<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Add Debit</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Admin Name : {{ this.localAdminName ? this.localAdminName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative">
                    <div class="p-px-3">
                        <div class="p-formgrid p-grid">
                            <DataTable :value="debits" :lazy="true" :paginator="true" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage" class="p-datatable-users"
                                data-key="bd1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <!-- <template v-slot:left>
                                        <h5 class="p-m-0">Add Component Debit Of Vision Creative Group</h5>
                                    </template> -->
                                    <template v-slot:right>
                                        <Button class="p-mr-2" label="Add Debit" icon="pi pi-plus" @click="onAddDebit" />
                                        <Button label="Filter" icon="pi pi-filter" @click="onFilter" />
                                        <!-- <Toast />
                                        <FileUpload mode="basic" name="demo[]" url="./upload.php" accept="*" :maxFileSize="1000000" @upload="onUpload" chooseLabel="Add Client Document"/> -->
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>
                                <Column field="id" header="Sr." headerStyle="width:5%">
                                    <template #body="{ index }">
                                        {{ ++index }}
                                    </template>
                                </Column>
                                <Column header="Date" field="date" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div v-if="data.bg7">{{ format_timestamp(data.bg7) }} </div>
                                        <div v-if="data.bg13">{{ format_timestamp(data.bg13) }} </div>
                                    </template>
                                </Column>
                                <Column header="Particular" field="bg23" headerStyle="width: 39%;"></Column>
                                <Column header="Credit" headerStyle="width: 12%" field="creditAmount">
                                    <template #body="{ data: { bg5: creditAmount } }">
                                        <div class="muted-text">
                                            {{ creditAmount ? formatCurrency(creditAmount) : 'N/A' }}
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Debit" headerStyle="width: 12%" field="creditAmount">
                                    <template #body="{ data: { bg12: debitAmount } }">
                                        {{ debitAmount ? formatCurrency(debitAmount) : 'N/A' }}
                                    </template>
                                </Column>
                                <Column header="Balance" headerStyle="width: 12%" field="creditAmount">
                                    <template #body="{ data: { bg18: balance } }">
                                        {{ balance ? formatCurrency(balance) : 'N/A' }}
                                    </template>
                                </Column>
                            </DataTable>
                            <Toast />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Debit Dialog starts here -->
    <Dialog class="p-fluid" v-model:visible="isAddDebit" header="Add Component Debit" :style="{ width: '800px' }"
        :modal="true">
        <div style="position: relative">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="component-name">
                            Component Name
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Dropdown id="component-name" v-model="componentName" :options="componentNamesList"
                            optionLabel="label" required="true" :class="{ 'p-invalid': isSubmitted && !componentName }"
                            placeholder="Select Component" emptyMessage="No Records" />
                        <small class="p-invalid p-error" v-if="v$.componentName.$error">{{
                            v$.componentName.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="usedCount">
                            Used Count
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="usedCount" v-model="usedCount" autocomplete="off" autofocus
                            :class="{ 'p-invalid': isSubmitted && !usedCount }" placeholder="Used Count"
                            @keypress="onlyNumber" />
                        <small class="p-invalid p-error" v-if="v$.usedCount.$error">{{
                            v$.usedCount.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="from-date">
                            From Date
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <Calendar v-model="fromDate" :showIcon="true" :monthNavigator="true" :yearNavigator="true"
                            :maxDate="todaysDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                            :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :manualInput="false"
                            id="from-date" :class="{ 'p-invalid': isSubmitted && !fromDate }" required>
                        </Calendar>
                        <small class="p-invalid p-error" v-if="v$.fromDate.$error">
                            {{ v$.fromDate.$errors[0].$message }}
                        </small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="document-type">
                            Remark
                            <!-- <span class="p-invalid p-error">*</span> -->
                        </label>
                        <Textarea id="remark" v-model="remark" :autoResize="true" rows="3" cols="30" placeholder="Remark" maxlength="250" />
                        <!-- <small class="p-invalid p-error" v-if="v$.remark.$error">{{
                            v$.remark.$errors[0].$message
                        }}</small> -->
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto" @click="addDebit"
                style="width: 90px;"></Button>
            <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- Add Debit Dialog ends here -->

    <!-- Filter dialog start here -->
    <Dialog v-model:visible="isFilter" :style="{ width: '600px' }" header="Account Statement Filter" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-6">
                    <label for="from-date">From Date</label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="filters.fromDate"
                        :maxDate="todaysDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                        :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :manualInput="false"></Calendar>
                </div>
                <div class="p-field p-col-6">
                    <label for="to-date">To Date</label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="filters.toDate"
                        :minDate="filters.fromDate || null" :maxDate="todaysDate" placeholder="DD/MM/YYYY"
                        dateFormat="dd/mm/yy" :yearRange="'2020:' + new Date().getFullYear()" appendTo="body"
                        :manualInput="false"></Calendar>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Search" icon="pi pi-search" class="p-button-text p-ml-auto"
                    @click="submitFilter" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- Filter dialog end here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers, numeric } from '@vuelidate/validators';
import moment from 'moment';

export default {
    data() {
        return {
            debits: [],
            v$: useValidate(),
            isAddDebit: false,
            isSubmitted: false,
            isFilter: false,
            componentName: "",
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            componentNamesList: [],
            fromDate: null,
            todaysDate: new Date(),
            remark: "",
            usedCount: "",
            filters: {
                toDate: "",
                fromDate: ""
            },
            routeParam: '',
            localAdminName: '',
        };
    },
    validations() {
        return {
            componentName: { required: helpers.withMessage('Please select employee', required) },
            usedCount: { required: helpers.withMessage('Please enter used count', required), numeric: helpers.withMessage('Only numbers are allowed', numeric) },
            fromDate: { required: helpers.withMessage('Please select from date', required) },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.getAdminDebitDetails({ client_id: this.routeParam });
        this.getComponentList({ clientId: this.routeParam });
    },
    methods: {
        getComponentList(e) {
            this.ApiService.getComponentList(e).then((data) => {
                if (data.status == 200) {
                    this.componentNamesList = data.data;
                } else {
                    this.componentNamesList = null;
                }
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAdminDebitDetails({ page_no: event.page, client_id: this.routeParam, fromdate: this.filters.fromDate, todate: this.filters.toDate });
        },
        onAddDebit() {
            this.v$.$reset();
            /** Open popup of add document gode goes here */
            this.isAddDebit = true;
            this.isSubmitted = false;
            this.fromDate = '';
            this.remark = '';
            this.usedCount = '';
            this.componentName = '';
        },
        addDebit() {
            this.isSubmitted = true;
            this.v$.$validate();
            var fields = {};
            fields["components_fk"] = this.componentName.value;
            fields["components_fk_value"] = this.componentName.label;
            fields["additional_rate_for_client"] = this.componentName.additional_rate_for_client;
            fields["used_count"] = this.usedCount;
            fields["current_date"] = moment(this.fromDate).format("YYYY-MM-DD");
            if (this.remark != '') {
                fields["remark"] = this.remark;
            }
            fields["clientId"] = this.routeParam;
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.addComponentDebitAction(fields).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        this.isAddDebit = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.componentName = '';
                        this.usedCount = '';
                        this.remark = '';
                        this.fromDate = '';
                        this.getAdminDebitDetails({ client_id: this.routeParam });
                    } else {
                        this.isAddDebit = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },
        getAdminDebitDetails(ev) {
            this.loading = true;
            this.ApiService.getAdminDebitDetails(ev).then((data) => {
                if (data.status == 200) {
                    this.debits = data.data;
                    this.totalRecords = data.count;
                    this.isFilter = false;
                } else {
                    this.debits = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },
        submitFilter() {
            if (this.filters.fromDate != "" && this.filters.fromDate != null) {
                this.filters.fromDate = moment(this.filters.fromDate).format("YYYY-MM-DD");
            }
            if (this.filters.toDate != "" && this.filters.toDate != null) {
                this.filters.toDate = moment(this.filters.toDate).format("YYYY-MM-DD");
            }
            this.getAdminDebitDetails({ client_id: this.routeParam, fromdate: this.filters.fromDate, todate: this.filters.toDate });
        },
        onFilter() {
            this.isFilter = true;
            this.filters.toDate = "";
            this.filters.fromDate = "";
        },
        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.routeParam },

            });
        },
        goToPrevious() {
            router.push({
                name: "service-setting",
                params: { adminSubId: this.routeParam },

            });
        },
        goToNext() {
            router.push({
                name: "template-actions",
                params: { adminSubId: this.routeParam },

            });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        formatCurrency(amount, currency = 'INR', minimumFractionDigits = 2) {
            let formatterAmount = new Intl.NumberFormat('en-IN', { minimumFractionDigits }).format(amount.toString());
            return (+0).toLocaleString('en-IN', { style: 'currency', currency })
                .replace('0.00', " " + formatterAmount)
        },
        dateFormat(date, format = 'DD-MM-YYYY') {
            return moment(date).format(format)
        },
        format_datetime(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return '-';
            }
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
    }

};
</script>

<style scoped></style>
